import { ably } from './ablyFactory';

export const ablyPublisher = async (channelName, message) => {
  try {
    const channel = ably.channels.get(channelName);
    await channel.publish('message', message, (err) => {
      if (err) return console.error(`error publishing message to ${channelName}`, err)
      console.log('successfully published to match')
    });
  } catch (error) {
    console.log(`error publishing message to ${channelName}`, error)
  }
}
