import { API_URL } from '@env';
import axios from 'axios'
// import { getItemAsync } from 'expo-secure-store';
import { getItem } from '../../../../storage'

const token = async () => {
  const secureToken = await getItem('rolldown-token')
  if (secureToken) {
    const sanitizedToken = secureToken.replace(/(['"])/g, '')
    return sanitizedToken
  }
  return null
}

const http = axios.create({
  baseURL: API_URL,
  timeout: 1000,
  headers: {
    'Content-Type': 'application/json',
  },
});

http.interceptors.request.use(
  async config => {
    const getToken = await token()
    if (getToken) {
      config.headers.Authorization = `Bearer ${getToken}`
    }
    return config;
  }
  , error => {
    console.log('request error', error);
    return Promise.reject(error);
  })

export { http }
