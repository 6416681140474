import React from 'react';
import { Text, StyleSheet } from 'react-native';

const TextComponent = ({ style, children }) => {
  return (
    <Text style={[styles.default, style]}>
      {children}
    </Text>
  )
}

const styles = StyleSheet.create({
  default: {
    fontFamily: 'Game-On',
  }
})

export default TextComponent;
