import React from 'react';
import { View, StyleSheet } from 'react-native';
import { default as Text } from '../../shared/TextComponent';

const Settings = () => {
  return (
    <View style={styles.largePad}>
      <Text style={[styles.smallPad, styles.standardText]}>Coming Soon!</Text>
    </View>
  );
}


// const black = '#000000';
const styles = StyleSheet.create({
  standardText: {
    fontSize: 10,
    lineHeight: 16,
  },
  largePad: {
    paddingTop: 40,
    paddingBottom: 20,
  },
  smallPad: {
    paddingTop: 10,
    paddingBottom: 10
  },
});

export default Settings;
