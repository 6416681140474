import { createContext } from 'react';


const initialState = {
  availableMoves: [],
  activePlayer: null,
  gameData: {},
  user: {
    uid: null,
    location: null
  },
  active: 1,
  handleActive: async () => {},
  diceResults: {
    die1: null,
    die2: null
  },
  handleSelected: async () => {},
  p1Selected: [],
  handleSelectedReset: async () => {},
  p2Selected: [],
  choosing: false,
  handleChoosing: () => {},
  reset: null,
  rollResults: {
    die1: null,
    die2: null,
    add: 0,
    sub: 0,
    mult: 0,
    div: 0,
  },
  handleGetWinner: async () => {},
  winner: null,
}

const GlobalContext = createContext(initialState)

export default GlobalContext;
