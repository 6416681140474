import React, { useState, useEffect } from 'react';
import { ImageBackground, StyleSheet, View, LogBox, Pressable, Alert, BackHandler } from 'react-native';
import NumberBarContainer from './NumberBarContainer';
import RollingContainer from './RollingContainer';
import GlobalProvider from '../../contexts/GlobalContext';
import { StackActions } from '@react-navigation/native';
import { ablyPublisher } from '../../functions/ablyPublisher';
import { audio } from '../../functions/soundGarden'
import { http } from '../shared/utils/http';
import { ably } from '../../functions/ablyFactory'
import { getMessage } from './MessageList';
import { initialGameData } from '../../constants/initialData';

import { default as Modal } from '../shared/modal/ModalContainer';
import ChoiceModal from '../shared/modal/ChoiceModal';
import { default as Text } from '../shared/TextComponent';

LogBox.ignoreLogs(['Setting a timer'])

const PlayArea = ({ navigation, matchId, user, onNavigate }) => {
  const matchChannel = ably.channels.get(matchId)

  const [message, setMessage] = useState({
    ...initialGameData,
    selected: {
      [user.uid]: [],
      'placeholder2': [{uid: null, username: null}],
    }
   });
  const [choosing, setChoosing] = useState(null)
  const [activePlayer, setActivePlayer] = useState(null)
  const [player1, setPlayer1] = useState({uid: null, username: null})
  const [player2, setPlayer2] = useState({uid: null, username: null})
  const [p1Selected, setP1Selected] = useState([])
  const [p2Selected, setP2Selected] = useState([])
  const [rollResults, setRollResults] = useState({})
  const [diceResults, setDiceResults] = useState({ die1: null, die2: null })
  const [winner, setWinner] = useState({ player: {uid: null}, message: null })
  const [winVisible, setWinVisible] = useState(false)
  const [choose11ModalVisible, setChoose11ModalVisible] = useState(false)
  const [choiceModalOptions, setChoiceModalOptions] = useState({
    disabled: false,
  })
  const [noNumberModalVisible, setNoNumberModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  // const [resigned, setResigned] = useState(null);
  const [availableMoves, setAvailableMoves] = useState([]);

  const isActivePlayer = activePlayer === user.uid
  const thisUser = player1 === user.uid ? 1 : 2

  const delay = ms => new Promise(resolve => setTimeout(resolve, ms))

  matchChannel.subscribe((message) => {
    setMessage(message.data)
  })

  const baseMessage = {
    players: [player1, player2],
    choosing: choosing,
    active: activePlayer,
  }

  const navigateToGameOver = async () => {
    const isWinner = winner.player.uid === user.uid
    const gameOverMessage = getMessage(isWinner);
    try {
      await matchChannel.detach()
      onNavigate(gameOverMessage)
    } catch (error) {
      console.log('End Game error', error);
    }
  }

  const handleElevenResult = (results) => {
    if (results.includes(11)) {

      if (results.includes(1)) {
        audio.resetSelection()
        setChoose11ModalVisible(true)
        return
      }
      audio.resetSelection()
      setChoiceModalOptions({
        disabled: true
      })
      setChoose11ModalVisible(true)
      return
    }
  }

  const parseMessage = async (message) => {
    const isJson = (str) => {
      try {
          JSON.parse(str);
      } catch (e) {
          return false;
      }
      return true;
    }
    if (isJson(message)) {
      setIsLoading(false)
      console.log('message', message);
      const data = JSON.parse(message)
      const p1Index = data.players.findIndex(player => player.uid === user.uid)
      const p2Index = p1Index === 0 ? 1 : 0
      const { die1, die2 } = data?.rollResults || { die1: null, die2: null };

      setPlayer1(data.players[p1Index])
      setPlayer2(data.players[p2Index])
      setP1Selected(data.selected[player1?.uid] || [])
      setP2Selected(data.selected[player2?.uid] || [])
      if (data?.winner) {
        const whoWonMessage = data.winner.uid === user.uid ? getMessage(true) : getMessage(false)
        setWinner({ player: data.winner, message: `${whoWonMessage}` })
        setDiceResults({ die1: null, die2: null })
        setChoosing(null)
        setActivePlayer(null)
        setWinVisible(true)
        return
      }
      setDiceResults({ die1, die2 })
      setChoosing(data.choosing || null)
      console.log('isActivePlayer', isActivePlayer);
      if (data.action && Object.keys(data?.action).length) {
        const { playerId, type} = data.action
        if (playerId === user.uid && type === 'noMoves') {
          console.log('no moves');
          setNoNumberModalVisible(true)
        }
        if (playerId !== user.uid && type === 'noMoves' ||
          playerId !== user.uid && type === 'reset') {

            await delay(3000)
          setDiceResults({ die1: null, die2: null })
        }
      }
      setActivePlayer(data.active.uid)
      if (isActivePlayer) {
        if (data?.action?.result?.length) {
          setAvailableMoves(data?.action.result)
          handleElevenResult(data?.action.result)
        } else {
          if (data?.action?.type === 'reset') {
            await delay(2750)
            setDiceResults({ die1: null, die2: null })
          }
        }
      }

    } else {
      const p1Index = message.players.indexOf(player => player.uid === user.uid)
      const p2Index = p1Index === 0 ? 1 : 0
      setPlayer1(message.players[p1Index])
      setPlayer2(message.players[p2Index])
      setP1Selected(message.selected[player1] || [])
      setP2Selected(message.selected[player2] || [])
      setActivePlayer(message.active)
      setChoosing(message.choosing)
      setRollResults(message.rollResults)
    }
  }

  const handleSelected = async (n) => {
    setP1Selected(prev => [...prev, n])
    setChoosing(null)
    setRollResults({})
    setDiceResults({})

    setActivePlayer(null)

    const data = {
      ...baseMessage,
      numberSelected: n,
      player: user.uid,
      type: 'selected',
    }

    try {
      await http.post(`/match/${matchId}/moves`, data)
    } catch (error) {
      console.log('error', error);
    }
  }

  const confirm11Use1 = async () => {
    setChoose11ModalVisible(false)
    setChoiceModalOptions({
      disabled: false
    })
    setP1Selected(prev => [...prev, 1])
    setChoosing(null)
    setRollResults({})
    setDiceResults({})
    const nextActivePlayer = toggleActive()
    setActivePlayer(nextActivePlayer)
    const data = {
      ...baseMessage,
      player: user.uid,
      type: 'selected',
      numberSelected: 1,
    }
    try {
      await http.post(`/match/${matchId}/moves`, data)
    } catch (error) {
      console.log('Use 11 API error', error);
    }
  }

  const reset11 = async () => {
    setChoose11ModalVisible(false)
    setChoiceModalOptions({
      disabled: false
    })
    setP2Selected([])
    setChoosing(null)
    setRollResults({})
    setDiceResults({})
    const data = {
      ...baseMessage,
      player: user.uid,
      type: 'reset',
    }
    try {
     await http.post(`/match/${matchId}/moves`, data)
    } catch (error) {
      console.log('Reset 11 API error', error);
    }
  }

  const noMovesEndTurn = async () => {
    setNoNumberModalVisible(false)
    setChoosing(null)
    setRollResults({})
    setDiceResults({})
  }

  const toggleActive = () => {
    const value = activePlayer === player1.uid ? player2.uid : player1.uid
    return value
  }

  const resetGame = async () => {
    navigateToGameOver()
  }

  const resignMatch = async () => {
    // TODO resign match
    // TODO send to server

    await ablyPublisher(matchId, JSON.stringify({
      ...baseMessage,
      resigned: user.uid,
      selected: {
        [player1?.uid]: p1Selected,
        [player2?.uid]: p2Selected,
      },
      active: null,
      rollResults: {},
    }))
    await matchChannel.detach()
    await matchChannel.presence.leaveClient(user.uid)
    navigation.dispatch(StackActions.replace('Landing'))
  }

  useEffect(() => {
    parseMessage(message)
  }, [message])

  //android only
  useEffect(() => {
    const backAction = () => {
      Alert.alert('Hold on!', 'Are you sure you want to resign?', [
        {
          text: 'Cancel',
          onPress: () => null,
          style: 'cancel',
        },
        { text: 'YES', onPress: () => resignMatch() },
      ]);
      return true;
    };

    const backHandler = BackHandler.addEventListener('hardwareBackPress', backAction);

    return () => backHandler.remove();
  }, []);

  const rollDice = async () => {
    try {
      audio.throwDice()
      const data = {
        players: [player1, player2],
        playerId: user.uid,
      }
      await http.post(`/match/${matchId}/roll`, data)
    } catch (
        error
    ) {
      console.log('rollDice error', error);
    }
  }

    const choices = [
    {
      text: 'Select Number 1',
      fn: async () => {
        audio.okClick()
        await confirm11Use1()
       }
    },
    {
      text: 'Reset Other Player',
      fn: () => {
        audio.okClick()
        reset11()
      }
    }
  ]

  return (
    <GlobalProvider.Provider
      value={{
        diceResults,
        p1Selected,
        p2Selected,
        handleSelected,
        choosing,
        rollResults,
        availableMoves,
        activePlayer
      }}
    >
      <ImageBackground source={require('../../../assets/background.png')} style={styles.image} >
        <View style={styles.container}>
          <Pressable
            style={styles.container}
            android_disableSound
            onPress={rollDice}
            disabled={!!choosing || !isActivePlayer}
          >
            <View style={styles.top}>
              <NumberBarContainer
                player={2}
                playerId={player2?.uid}
                disabled={true}
              />
              <Text style={styles.playerText}>{player2?.username ? player2?.username : 'Player 2'}</Text>
            </View>
            <View style={styles.rollingContainer}>
              <RollingContainer
                active={isActivePlayer}
                loading={isLoading} />
            </View>
            <View style={styles.bottom}>
              <Text style={styles.playerText}>{player1?.username ? player1?.username : 'You'}</Text>
              <NumberBarContainer
                player={1}
                playerId={player1?.uid}
                disabled={false}
              />
            </View>
          </Pressable>
        </View>
      </ImageBackground>

      <Modal
        visible={winVisible}
        animationType='fade'
        heading={winner.player.uid === user.uid ? 'You Win!' :'Game Over!'}
        bodyText={winner.message}
        confirmFunction={resetGame}
        sound={() => audio.win()}
      />
      <Modal
        visible={noNumberModalVisible}
        animationType='fade'
        heading={`Player 1`}
        bodyText={'No Numbers Available'}
        confirmFunction={noMovesEndTurn}
        position={thisUser}
        sound={() => audio.noMoves()}
      />

      <ChoiceModal
        visible={choose11ModalVisible}
        heading='Your Choice!'
        choices={choices}
        disabled={choiceModalOptions.disabled}
        position={thisUser}
      />
    </GlobalProvider.Provider>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'space-between'
  },
  top: {
    justifyContent: 'flex-start'
  },
  bottom: {
    justifyContent: 'flex-end'
  },
  rollingContainer: {
    flexGrow: 1,
  },
    image: {
    flex: 1,
    resizeMode: 'cover',
    justifyContent: 'center'
  },
  playerText: {
    textAlign: 'center',
    lineHeight: 16,
    fontSize: 10,
    paddingTop: 5
  }
});

export default PlayArea;
