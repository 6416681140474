import { Audio } from 'expo-av'

const files = {
  keyClick: require('../../assets/sounds/key_click.mp3'),
  okClick: require('../../assets/sounds/ok_click.mp3'),
  throwDice: require('../../assets/sounds/throw_dice.mp3'),
  noMoves: require('../../assets/sounds/no_moves.mp3'),
  win: require('../../assets/sounds/win.mp3'),
  resetSelection: require('../../assets/sounds/reset_selection.mp3'),
  findMatchGo: require('../../assets/sounds/find_match_go.mp3'),
  roll12: require('../../assets/sounds/roll_12.mp3')
}

let audio = {}

const setupAudio = async () => {
  Audio.setAudioModeAsync({
    allowsRecordingIOS: false,
    interruptionModeIOS: Audio.INTERRUPTION_MODE_IOS_DO_NOT_MIX,
    playsInSilentModeIOS: true,
    shouldDuckAndroid: true,
    interruptionModeAndroid: Audio.INTERRUPTION_MODE_ANDROID_DO_NOT_MIX,
  });

  Object.keys(files).map(async key => {
    try {
      const res = files[key]
      const { sound } = await Audio.Sound.createAsync(res);
      await sound.setStatusAsync({
        volume: 1,
      });

    audio[key] = async () => {
      try {
        await sound.setPositionAsync(0);
        await sound.playAsync();
      } catch (error) {
        console.warn('sound error', { error });
        throw error
      }
    };
    } catch (error) {
      console.warn('sound error', { error });
    }

  });

}

export {
  setupAudio,
  audio
}
