import React, { useState } from 'react';
import { TextInput, StyleSheet, Pressable, View } from 'react-native';
import { AntDesign } from '@expo/vector-icons';

const TextInputComponent = ({ isDisabled, style, placeholder, onChange, value, hasConfirm = false, onConfirm }) => {
  const [text, setText] = useState('');

  const handleChange = (value) => {
    const sanitized = value.replace( /[^a-zA-Z0-9 ]/gm, '')
    setText(sanitized)
    onChange(sanitized)
  }

  const handleConfirm = () => {
    onConfirm()
  }

  return (
    <View style={styles.combinedLabel}>
      <TextInput
        style={[styles.standardText, styles.inputBox, style, isDisabled ? styles.inputBoxDisabled : null]}
        placeholder={placeholder}
        onChangeText={newText => handleChange(newText)}
        value={value || text}
        editable={!isDisabled}
        maxLength={16}
      />
      {hasConfirm &&
        <Pressable style={styles.confirmCheck} onPress={handleConfirm}>
          <AntDesign name="checkcircle" size={24} color="green" />
        </Pressable>
      }
    </View>
  );
}

const black = '#000000';
const white = '#ffffff';
const disabled = '#c1c7d2';
const styles = StyleSheet.create({
  standardText: {
    fontSize: 10,
    lineHeight: 16,
  },
  inputBox: {
    backgroundColor: white,
    borderRadius: 10,
    width: 250,
    height: 40,
    borderColor: black,
    borderWidth: 1,
    paddingLeft: 10,
    fontFamily: 'Game-On',
  },
  inputBoxDisabled: {
    backgroundColor: disabled,
  },
  combinedLabel: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  confirmCheck: {
    paddingLeft: 20,
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export default TextInputComponent;
