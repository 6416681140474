import React from 'react';
import { View, StyleSheet } from 'react-native';
import AutoLink from 'react-native-autolink';
import { default as Text } from '../../shared/TextComponent';

const About = () => {
  return (
    <View style={[styles.container, styles.largePad]}>
      <Text style={[styles.smallPad, styles.titleText]}>Rolldown</Text>
      <Text style={[styles.smallPad, styles.standardTextCentered]}>version 0.5.0-beta</Text>
      <Text style={[styles.smallPad, styles.standardTextCentered]}>
        Contact Us:
      </Text>
      <View style={styles.paddingTop}>
        <AutoLink
          text="www.rolldowngame.com"
          linkProps={({text}) => (<Text>{text}</Text>)}
          linkStyle={styles.link}
        />
      </View>
      <Text style={[styles.smallPad, styles.standardTextCentered]}>Copyright © 2022</Text>
    </View>
  );
}

const blue = '#7491b8'
const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'space-around',
  },
  link: {
    fontFamily: 'Game-On',
    fontSize: 14,
    lineHeight: 28,
    textAlign: 'center',
    paddingTop: 10,
    color: blue,
  },
  standardTextCentered: {
    fontSize: 10,
    lineHeight: 16,
    textAlign: 'center',
  },
  titleText: {
    fontSize: 28,
    paddingTop: 20,
    textAlign: 'center'
  },
  largePad: {
    paddingTop: 40,
    paddingBottom: 20,
  },
  smallPad: {
    paddingTop: 10,
    paddingBottom: 10
  },
});

export default About;
