const initialRoll = {
  add: null,
  sub: null,
  mult: null,
  div: null,
  die1: null,
  die2: null,
}

const initialDice = {
  die1: initialRoll.die1,
  die2: initialRoll.die2
}

const initialPlayers = [
  null,
  null,
]

const initialRollResults = [
  {
    player: null,
    roll: initialRoll,
  }
]

const initialBase = {
  selected: {
    'placeholder1': [],
    'placeholder2': [],
  },
  active: null,
  choosing: null,
  winner: null,
  reset: null,
}
const initialGameData = {
  rollResults: {...initialRoll},
  players: initialPlayers,
  ...initialBase
}

export {
  initialRoll,
  initialDice,
  initialPlayers,
  initialRollResults,
  initialGameData,
}
