const winningMessages = [
  "Congratulations!",
  "What A Great Game!",
  "Fabulous Game!",
  "Fantastic Game!",
  "Hot Dice!",
  "Well Played!",
  "You're A Winner!",
  "Hot Roller!",
  "Hooray! You Won!",
  "Best Game Yet!"
];

const losingMessages = [
  "Better Luck Next Time!",
  "You'll Get It Next Time!",
  "That's How The Dice Roll!",
  "Unlucky! Try Again!",
  "No Worries! They Got Lucky!",
  "Luck Was Not On Your Side!",
  "Don't Give Up!",
  "It's Ok. You Will Win Next Time!",
];

export const getMessage = (winner) => {
  const winningMessage = winningMessages[Math.floor(Math.random() * winningMessages.length)];
  const losingMessage = losingMessages[Math.floor(Math.random() * losingMessages.length)];

  if (winner) {
    return winningMessage;
  }

  return losingMessage;
}
