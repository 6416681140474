import React from 'react';
import { StackActions } from '@react-navigation/native';
import { ably } from '../../functions/ablyFactory'

import PlayArea from './PlayArea';

const PlayAreaContainer = ({route, navigation }) => {
  const { matchId, user } = route.params
  console.log('matchChannel', matchId);
  const matchChannel = ably.channels.get(matchId)

  matchChannel.presence.enterClient(user.uid, (err) => {
    if (err) return console.error('error entering client', err)
    console.log('successfully present in match')
    matchChannel.subscribe((message) => {
      console.log('subscribed to match channel', message);
    })
  })

  const handleNavigation = (message) => {
    navigation.dispatch(StackActions.replace(
      'GameOver', {
        winner: message,
      }
    ))
  }

  return (
    <PlayArea
      user={user}
      navigation={navigation}
      matchId={matchId}
      onNavigate = {handleNavigation}
    />
  );
}

export default PlayAreaContainer;
