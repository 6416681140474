import AsyncStorage from '@react-native-async-storage/async-storage';

const getItem = async (key) => {
  return await AsyncStorage.getItem(key);
}

const setItem = async (key, value) => {
  return await AsyncStorage.setItem(key, value);
}

export { getItem, setItem };
