import React from 'react'
import { Svg, Rect, Circle } from 'react-native-svg'

const halfIt = val => {
  return (val / 2).toFixed(2)
}

const Dot = props =>
  <Circle {...props} r={4} fill='black' />

const Square = () =>
  <Rect
    x={4}
    y={4}
    width={44}
    height={44}
    rx={10}
    fill='white'
    stroke='#000000'
    strokeWidth={3}
  />

const Side = ({ children }) => {
  return (
    <Svg height={50} width={50}>
      <Square />
      {children}
    </Svg>
  )
}
export const SideOne = () =>
  <Side>
    <Dot cx={halfIt(50)} cy={halfIt(50)} />
  </Side>

export const SideTwo = () =>
  <Side>
    <Dot cx={halfIt(75)} cy={halfIt(75)} />
    <Dot cx={halfIt(25)} cy={halfIt(25)} />
  </Side>

export const SideThree = () =>
  <Side>
    <Dot cx={halfIt(75)} cy={halfIt(75)} />
    <Dot cx={halfIt(50)} cy={halfIt(50)} />
    <Dot cx={halfIt(25)} cy={halfIt(25)} />
  </Side>

export const SideFour = () =>
  <Side>
    <Dot cx={halfIt(75)} cy={halfIt(75)} />
    <Dot cx={halfIt(75)} cy={halfIt(25)} />
    <Dot cx={halfIt(25)} cy={halfIt(75)} />
    <Dot cx={halfIt(25)} cy={halfIt(25)} />
  </Side>

export const SideFive = () =>
  <Side>
    <Dot cx={halfIt(75)} cy={halfIt(75)} />
    <Dot cx={halfIt(75)} cy={halfIt(25)} />
    <Dot cx={halfIt(50)} cy={halfIt(50)} />
    <Dot cx={halfIt(25)} cy={halfIt(75)} />
    <Dot cx={halfIt(25)} cy={halfIt(25)} />
  </Side>

export const SideSix = () =>
  <Side>
    <Dot cx={halfIt(75)} cy={halfIt(75)} />
    <Dot cx={halfIt(75)} cy={halfIt(50)} />
    <Dot cx={halfIt(75)} cy={halfIt(25)} />
    <Dot cx={halfIt(25)} cy={halfIt(75)} />
    <Dot cx={halfIt(25)} cy={halfIt(50)} />
    <Dot cx={halfIt(25)} cy={halfIt(25)} />
  </Side>

export default Side
