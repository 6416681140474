import React, { useEffect } from 'react';
import { LogBox, StyleSheet, SafeAreaView, Text } from 'react-native';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { StatusBar } from 'expo-status-bar';
import { useFonts } from 'expo-font';
import { PlayAreaContainer } from './src/components/PlayArea';
import MatchMakingPage from './src/components/MatchMakingPage/MatchMakingPage'
import LandingPage from './src/components/LandingPage/LandingPage'
import GameOverPage from './src/components/GameOver/GameOverPage'
import HowToPlayPage from './src/components/HowToPlay/HowToPlayPage';
import SettingsPage from './src/components/Settings/SettingsPage';
import { setupAudio } from './src/functions/soundGarden';

LogBox.ignoreLogs(['Setting a timer'])
LogBox.ignoreLogs(['Warning: Async Storage has been extracted from react-native core']);


export default function App() {

  useEffect(() => {
    setupAudio()
  }, []);


  let [fontsLoaded] = useFonts({
    'Game-On': require('./assets/fonts/Game-On.otf')
  })
  const RootStack = createStackNavigator()

  if (!fontsLoaded) {
    return <Text>Loading...</Text>
  }

  return (
    <NavigationContainer>
      <SafeAreaView style={styles.container}>
        <RootStack.Navigator screenOptions={{
          headerShown: false,
        }}>
          <RootStack.Screen name='Landing' component={LandingPage} />
          <RootStack.Screen name='MatchMaking' component={MatchMakingPage} />
          <RootStack.Screen name='PlayArea' component={PlayAreaContainer} />
          <RootStack.Screen name='GameOver' component={GameOverPage} />
          <RootStack.Screen name='HowToPlay' component={HowToPlayPage} />
          <RootStack.Screen name='Profile' component={SettingsPage} />
        </RootStack.Navigator>
        <StatusBar style='auto' />
      </SafeAreaView>
    </NavigationContainer>
  )
}

const white = '#FFFFFF'
const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: white,
  },
});
