import React from 'react';
import { ImageBackground, View, StyleSheet, Pressable } from 'react-native';
import { default as Text } from '../shared/TextComponent'
import Dice from '../dice/SmallDice'
import { audio } from '../../functions/soundGarden'

const HowToPlayPage = ({ navigation }) => {
  const backToLanding = () => {
    audio.okClick()
    navigation.popToTop()
  }

  return (
    <ImageBackground style={styles.image}
      source={require('../../../assets/background.png')}>
      <View style={styles.container}>
        <Text style={styles.howTo}>How To Play</Text>

        <Text style={[styles.standardText, styles.smallPad]}>
          Rolldown is a dice game and a math game. Roll a pair of dice and then decide
          which number to play based on adding, subtracting, multiplying or dividing the
          numbers on the dice.
        </Text>

        <View style={styles.diceContainer}>
          <View style={styles.die1}>
            <Dice side={2} />
          </View>
          <View style={styles.die2}>
            <Dice side={4} />
          </View>
        </View>

        <Text style={[styles.standardText, styles.smallPad]}>
          The first person to have selected all their numbers wins!
        </Text>

        <View style={styles.diceContainer}>
          <View style={styles.die1}>
            <Dice side={6} />
          </View>
          <View style={styles.die2}>
            <Dice side={5} />
          </View>
        </View>
        <Text style={[styles.standardText, styles.smallPad]}>
          But there&apos;s a twist! If a player rolls a 5 and a 6 (11), they can choose to select
          Number 1 (6-5), or reset their opponent&apos;s deck. 
        </Text>

        <View style={styles.diceContainer}>
          <View style={styles.die1}>
            <Dice side={6} />
          </View>
          <View style={styles.die2}>
            <Dice side={6} />
          </View>
        </View>

        <Text style={[styles.standardText, styles.smallPad]}>
          If a player rolls a 12, their
          deck will get reset.
        </Text>

        <Pressable
          style={styles.gotIt}
          android_disableSound={true}
          onPress={backToLanding}
        >
          <Text
            style={styles.confirmTextStyle}>
            Got It!
        </Text>
        </Pressable>
      </View>

    </ImageBackground>
  )
}

export default HowToPlayPage

// const backgroundColor = '#603FA3'
const textColor = 'blue'
const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
    padding: 50,
  },
  howTo: {
    fontSize: 18,
    lineHeight: 20,
    paddingTop: 10,
    color: textColor,
  },
  smallPad: {
    paddingTop: 10
  },
  diceContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    minHeight: 30,
    padding: 20,
  },
  die1: {
    paddingRight: 10,
  },
  die2: {
    paddingLeft: 10,
  },
  standardText: {
    fontSize: 10,
    lineHeight: 16,
  },
  image: {
    flex: 1,
    resizeMode: 'cover',
    justifyContent: 'center'
  },
  // confirmButton: {
  //   backgroundColor: backgroundColor,
  //   borderRadius: 10,
  //   width: 250,
  //   height: 50,
  //   elevation: 2,
  //   marginBottom: 10,
  //   justifyContent: 'center',
  //   textAlign: 'center'
  // },
  confirmTextStyle: {
    color: textColor,
    textAlign: 'center',
    fontSize: 10,
    lineHeight: 20
  },
  gotIt: {
    paddingTop: 30,
  }
})
