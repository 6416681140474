import React from 'react'
import { ImageBackground, View, StyleSheet, TouchableHighlight, Platform } from 'react-native';
import { StackActions } from '@react-navigation/native';
import { default as Text } from '../shared/TextComponent'
import { audio } from '../../functions/soundGarden'

const GameOverPage = ({ route, navigation }) => {
  const { winner } = route.params

  const handleNewGame = () => {
    audio.okClick()
    navigation.dispatch(StackActions.replace('Landing'))
  }

  return (
    <ImageBackground source={require('../../../assets/background.png')} style={styles.image} >
      <View style={styles.container}>
        <Text style={styles.titleText}>Game Over!</Text>
        <Text style={styles.playerText}>{winner}</Text>
        <TouchableHighlight
          style={styles.confirmButton}
          onPress={handleNewGame}>
          <Text style={styles.confirmTextStyle}>Back To Lobby</Text>
        </TouchableHighlight>
      </View>
    </ImageBackground>
  )
}

const bgColor = '#603FA3'
const txtColor = '#FFFFFF'
const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
    padding: 20,
  },
  image: {
    flex: 1,
    resizeMode: 'cover',
    justifyContent: 'center'
  },
  titleText: {
    fontSize: 28,
    lineHeight: 30,
    paddingTop: 20,
  },
  playerText: {
    fontSize: 16,
    lineHeight: 24,
    paddingTop: 8,
  },
  confirmButton: {
    backgroundColor: bgColor,
    borderRadius: 10,
    width: 175,
    height: 45,
    elevation: 2,
    justifyContent: 'center',
    textAlign: 'center'
  },
  confirmTextStyle: {
    color: txtColor,
    textAlign: 'center',
    fontSize: 12,
    margin: Platform.OS === 'ios' ? 10 : 0,
    padding: Platform.OS === 'ios' ? 10 : 0,
  },
})

export default GameOverPage;
