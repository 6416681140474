import React, {useState, useEffect, useContext } from 'react';
import { Animated, Easing, ImageBackground, View, StyleSheet, LogBox, Pressable, Platform } from 'react-native';
import { AntDesign } from '@expo/vector-icons';
import * as Storage from '../../../storage'
import GlobalProvider from '../../contexts/GlobalContext';
import AppStateMonitor from '../../functions/AppStateMonitor';

import { http } from '../shared/utils/http';
import { ably } from '../../functions/ablyFactory'
import { audio } from '../../functions/soundGarden';
import { StackActions } from '@react-navigation/native';

import {default as Text} from '../shared/TextComponent';

LogBox.ignoreLogs(['Setting a timer'])

const LoadingSpinner = () => {
    let spinValue = new Animated.Value(0);

    Animated.timing(
        spinValue,
      {
        toValue: 12,
        duration: 10000,
        easing: Easing.linear,
        useNativeDriver: true
      }
    ).start()

    const spin = spinValue.interpolate({
      inputRange: [0, 1],
      outputRange: ['0deg', '360deg']
    })

    return (
      // eslint-disable-next-line react-native/no-inline-styles
      <Animated.View style={{transform: [{rotate: spin}], alignSelf: 'center' }}>
        <AntDesign name="loading2" size={24} color="black" />
      </Animated.View>
    )
  }

const LandingPage = ({ navigation }) => {
  const state = useContext(GlobalProvider)
  const [user, setUser] = useState(state.user)

  const channel = ably.channels.get('lobby')

  const initAuth = async () => {
    console.log('mobile initAuth')
    try {
      const secureToken = await Storage.getItem('rolldown-token')
      if (secureToken) {
        console.log('i already have a token', secureToken);
        const { data } = await http.get('/auth')
        if (data) {
          await Storage.setItem('rolldown-token', JSON.stringify(data.user.token))
          setUser({uid: data.user.id, username: data.user.username})
        }
      } else {
        console.log('i dont have a token');
        const {data: { user }} = await http.get('/auth/anon')
        await Storage.setItem('rolldown-token', JSON.stringify(user.token));
        setUser({uid: user.id})
      }
    } catch (error) {
      console.log('some kind of error in auth', error)
    }
  }

  const initWebAuth = async () => {
    try {
      console.log('platform', Platform.OS);
      console.log('looking for web token')
      const webSecureToken = await Storage.getItem('rolldown-token')
      console.log('made it past looking')

      if (webSecureToken) {
        console.log('secureToken', webSecureToken)
        const getCredentials = await http.get('/auth')
        if (getCredentials.data.user) {
          setUser(getCredentials.data.user)
          await Storage.setItem('rolldown-token', JSON.stringify(getCredentials.data.user.token))
        }
      } else {
        console.log('i dont have a web token');
        try {
          console.log('trying to call web anon')
          const anonymous = await http.get('/auth/anon')
          console.log('called anonymous', anonymous.data);
          await Storage.setItem('rolldown-token', JSON.stringify(anonymous.data.user.token))
          setUser({uid: anonymous.data.user.id})
        } catch (error) {
          console.log('error', error)
        }
      }
    } catch (error) {
      console.log('some kind of error in webauth', error)
    }
  }

  const addToMatchMaking = async () => {
    try {
      channel.presence.leaveClient(user.uid)
      channel.detach()
      setUser({ ...user, location: 'matchMaking' })
        navigation.dispatch(StackActions.replace(
          'MatchMaking', {
            user: user,
          }
        ))
    } catch (error) {
      console.log('addToMatchmaking error', error)
    }
  }

  useEffect(() => {
    if (Platform.OS === 'web') {
      initWebAuth()
    } else {
      initAuth()
    }
  }, [])

  const moveToMatchMaking = async () => {
    audio.findMatchGo()
    try {
      // await removeFromLobby(user.uid)
      await addToMatchMaking(user.uid)
    } catch (error) {
      console.log('error', error)
      setUser({ uid: user.uid, location: 'lobby' })
    }
  }

  const howToPlay = () => {
    audio.okClick()
    navigation.navigate('HowToPlay')
  }

  const goToProfile = () => {
    audio.okClick()
    navigation.navigate('Profile', {id: user.uid})
  }

  return (
    <GlobalProvider.Provider value={{ user }}>
      <ImageBackground source={require('../../../assets/background.png')} style={styles.image}>
        <AppStateMonitor />
        <View style={styles.container}>
          <Text style={styles.titleText}>Rolldown</Text>
          <Pressable
            android_disableSound={true}
            onPress={howToPlay}>
            <Text style={styles.linkTextStyle}>
              How To Play
            </Text>
          </Pressable>
          <View style={styles.center}>
            <Pressable
              style={styles.confirmButton}
              android_disableSound={true}
              onPress={goToProfile}>
              <Text style={styles.confirmTextStyle}>Settings</Text>
            </Pressable>
            <Pressable
              style={styles.confirmButton}
              android_disableSound={true}
              onPress={moveToMatchMaking}>
              <Text style={styles.confirmTextStyle}>Find New Game</Text>
            </Pressable>
          </View>
            {user?.uid && <Text style={styles.minorText} >{user.uid}</Text>}
            {!user?.uid && <LoadingSpinner /> }
        </View>
      </ImageBackground>
    </GlobalProvider.Provider>
  )
}


const white = '#FFFFFF'
const purple = '#603FA3'
const blue = '#7491b8'
const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'space-around',
    padding: 50,
  },
  image: {
    flex: 1,
    resizeMode: 'cover',
    justifyContent: 'center'
  },
  titleText: {
    fontSize: 28,
    paddingTop: 20,
    textAlign: 'center'
  },
  minorText: {
    fontSize: 8,
    lineHeight: 16,
  },
  confirmButton: {
    backgroundColor: purple,
    borderRadius: 10,
    width: 250,
    height: 50,
    elevation: 2,
    marginBottom: 10,
    justifyContent: 'center',
    textAlign: 'center'
  },
  confirmTextStyle: {
    color: white,
    textAlign: 'center',
    fontSize: 10,
    lineHeight: 20
  },
  linkTextStyle: {
    ...Platform.select({
      ios: {
        fontWeight: 'bold',
        lineHeight: 28,
      },
      android: {
        fontWeight: '600',
      },
    }),
    color: blue,
    textAlign: 'center',
    fontSize: 14,
  },
  center: {
    justifyContent: 'center',
    alignItems: 'center'
  }
})

export default LandingPage;
