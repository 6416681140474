import React from 'react';
import { View, Modal, StyleSheet, Pressable, Platform } from 'react-native';
import { default as Text } from '../TextComponent';

const { ios } = Platform.OS;

const ModalContainer = ({
  animationType,
  visible,
  heading,
  confirmText,
  cancelText,
  bodyText,
  confirmFunction,
  cancelFunction,
  position,
  sound,
}) => {
  const autoTimer = visible && !confirmText

  const timedClose = () => {
    setTimeout(() => {
      confirmFunction()
    }, 3500)
  }

  const playSound = () => {
    sound()
  }

  const modalPosition = position === 2 ? styles.player2 : null

  return (
    <Modal animationType={animationType} visible={visible} transparent={true}>
      {autoTimer && timedClose()}
      {(visible && sound) && playSound()}
      <View style={[styles.centeredView, modalPosition]}>
        <View style={styles.modalView}>
          {heading &&
            <Text style={styles.modalText}>{heading}</Text>
          }
          {bodyText &&
            <Text style={styles.modalText}>{bodyText}</Text>
          }
            {confirmText && <Pressable
              style={styles.modalConfirmButton}
              android_disableSound={true}
              onPress={confirmFunction}
            >
              <Text style={styles.modalConfirmTextStyle}>{confirmText}</Text>
            </Pressable>}
          {cancelText &&
            <Pressable
              style={styles.modalCancelButton}
              android_disableSound={true}
              onPress={cancelFunction}
            >
              <Text style={styles.modalCancelTextStyle}>{cancelText}</Text>
            </Pressable>
          }
        </View>
      </View>
    </Modal>
  );
};

const white = '#FFFFFF'
const black = '#000000'
const purple = '#603FA3'
const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    alignItems: 'center',
    marginTop: 50,
  },
  player2: {
    justifyContent: 'flex-end',
    marginBottom: 50
  },
  modalView: {
    margin: 20,
    backgroundColor: white,
    borderRadius: 20,
    padding: 50,
    width: 300,
    height: 'auto',
    alignItems: 'center',
    shadowColor: black,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
  },
  modalText: {
    marginBottom: 30,
    fontSize: 10,
    lineHeight: 16,
    paddingTop: 15,
    textAlign: 'center',
  },
  modalCancelButton: {
    padding: 10,
    elevation: 2,
  },
  modalConfirmButton: {
    backgroundColor: purple,
    borderRadius: 10,
    width: 150,
    height: 45,
    elevation: 2,
    marginBottom: 15,
    justifyContent: 'center',
    textAlign: 'center'
  },
  modalCancelTextStyle: {
    color: black,
    textAlign: 'center',
    fontSize: 12,
    margin: 10,
    padding: 5,
  },
  modalConfirmTextStyle: {
    color: white,
    textAlign: 'center',
    fontSize: ios ? 6 : 12,
    margin: Platform.OS === 'ios' ? 12 : 0,
    padding: Platform.OS === 'ios' ? 12 : 0,
  },
});

export default ModalContainer;
