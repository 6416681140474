const uniqueSet = (rollResults) => {
  let initSet = (rollResults && [...new Set(Object.values(rollResults))]) || []
  const index = initSet.indexOf(0)
    if (index >= 0) {
      initSet.splice(index, 1)
    }

  return initSet
};
const isNumberAvailable = (n, availableMoves) => {
  if (availableMoves.length) {
    return availableMoves.includes(n)
  }
}

const checkAnyNumberAvailable = (rollResults, selected) => {
  const uniqueAvailable = uniqueSet(rollResults)

  const availableBoolArray = uniqueAvailable.map(avail => {
    if (selected.length) {
      if (selected.includes(avail)) return false

    }
    return true
  })

  const noneAvailable = val => val === false
  const hasFalse = availableBoolArray.length &&  availableBoolArray.every(noneAvailable)

  if (hasFalse) return false
  return true
}

export {
  checkAnyNumberAvailable,
  isNumberAvailable
}
