import { useRef, useEffect } from 'react'
import { AppState } from 'react-native'
// import { db } from '../firebase/config'
// import {
//   collection,
//   doc,
// } from 'firebase/firestore';

const AppStateMonitor = ({channel}) => {

  const appState = useRef(AppState.currentState)
  // const lobbyRef = firebase.firestore().collection('lobby').doc('users')
  
  const handleAppStateChange = nextAppState => {
    
    if (nextAppState === 'background' || nextAppState === 'inactive') {
      console.log('in the background')
      //TODO: remove user form matchmaking, game or lobby if inactive
      // for more than 10 seconds in background
      // lobbyRef.update({
      //   users: firebase.firestore.FieldValue.arrayRemove(user.uid)
      // })
    }
    appState.current = nextAppState;
    console.log("AppState", appState.current);

  }

  useEffect(() => {
    AppState.addEventListener('change', handleAppStateChange)
    return () => {
      AppState.removeEventListener('change', handleAppStateChange)
    }
  }, [])
  return null
}

export default AppStateMonitor
