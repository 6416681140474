import React, { useContext } from 'react';
import { View, StyleSheet } from 'react-native';
import { default as Text } from '../shared/TextComponent'
import Dice from '../dice/Dice'
import GlobalContext from '../../contexts/GlobalContext';

const RollingContainer = ({ loading, active }) => {
  const {
    choosing,
    diceResults,
    // activePlayer,
  } = useContext(GlobalContext)

  const { die1, die2 } = diceResults;
  // const active = activePlayer === playerId

  const RollComponent = () => {
    return (
      <View style={styles.container}>
        <View style={styles.diceContainer}>
          <View style={styles.die1}>
            {die1 && <Dice side={die1 || 1} />}
          </View>
          <View style={styles.die2}>
            {die2 && <Dice side={die2 || 1} />}
          </View>
        </View>
        {loading && <Text style={styles.player}>Connecting...</Text>}
        {(!loading && !choosing) && <Text style={styles.player}>{active ? 'Tap to roll' : 'Their Turn'}</Text>}
        {(!loading && choosing) && <Text style={styles.player}>{active ? 'Your Choice' : 'Player Choosing . . .'}</Text>}
      </View>
    )
  }

  return (
    <RollComponent />
  );
}

const styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 1,
  },
  diceContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    minHeight: 100,
  },
  // titleText: {
  //   padding: 10,
  //   fontSize: 10,
  // },
  die1: {
    paddingRight: 10,
  },
  die2: {
    paddingLeft: 10,
  },
  player: {
    paddingTop: 40,
    lineHeight: 12,
    fontSize: 10
  },
});

export default RollingContainer;
