import React, { useState, useContext } from 'react';
import { View, StyleSheet } from 'react-native';
import { default as Modal } from '../shared/modal/ModalContainer';
import Number from './Number';
import GlobalContext from '../../contexts/GlobalContext';
import { isNumberAvailable } from '../../functions/availableCheck'
import { audio } from '../../functions/soundGarden'

const NumberBarContainer = ({ player, playerId, disabled }) => {
  const {
    p1Selected,
    handleSelected,
    p2Selected,
    availableMoves,
    activePlayer,
  } = useContext(GlobalContext);

  const active = activePlayer === playerId
  const selected = player === 1 ? p1Selected : p2Selected;

  const numbers = Array.from({ length: 10 }, (_, index) => index + 1);

  const [chosenNumber, setChosenNumber] = useState(null);
  const [turnModalVisible, setTurnModalVisible] = useState(false);

  const isNumberSelected = (n) => {
    if (selected.length && n) {
      return selected.includes(n)
    }
    return false
  };

  const pushSelectedNumber = async (n) => {
    await handleSelected(n)
  }

  const toggleTurnModal = () => {
    setTurnModalVisible(!turnModalVisible);
  };

  const handleFinishTurn = async () => {
    audio.okClick()
    await pushSelectedNumber(chosenNumber)
    setChosenNumber(null)
    toggleTurnModal()
  }

  const clearEndTurn = () => {
    setChosenNumber(null)
    toggleTurnModal()
  };

  const handleChosen = (n) => {
    setChosenNumber(n)
    toggleTurnModal();
  };

  return (
    <View style={[styles.container, active ? styles.active : null]}>
      <View style={styles.numbers}>
        {numbers.map((number) => (
          <Number
            key={number}
            disabled={disabled}
            number={number}
            player={player}
            selected={isNumberSelected(number)}
            available={activePlayer && isNumberAvailable(number, availableMoves)}
            chosen={activePlayer && chosenNumber}
            onChosen={handleChosen}
          />
        ))}
      </View>
      <Modal
        visible={activePlayer && turnModalVisible}
        animationType='fade'
        heading='End Turn?'
        confirmFunction={handleFinishTurn}
        confirmText='Ok'
        cancelFunction={clearEndTurn}
        cancelText='Cancel'
        position={player}
        />
    </View>
  );
};

const borderColor = '#5da597'
const styles = StyleSheet.create({
  container: {
    height: 175,
    // borderWidth: 2,
  },
  // centeredView: {
  //   flex: 1,
  //   justifyContent: 'center',
  //   alignItems: 'center',
  //   marginTop: 22,
  // },
  // modalView: {
  //   margin: 20,
  //   backgroundColor: 'white',
  //   borderRadius: 20,
  //   padding: 35,
  //   alignItems: 'center',
  //   shadowColor: '#000',
  //   shadowOffset: {
  //     width: 0,
  //     height: 2,
  //   },
  //   shadowOpacity: 0.25,
  //   shadowRadius: 3.84,
  //   elevation: 5,
  // },
  // modalText: {
  //   marginBottom: 15,
  //   textAlign: 'center',
  //   fontWeight: 'bold',
  // },
  // modalCancelButton: {
  //   padding: 10,
  //   elevation: 2,
  // },
  // modalConfirmButton: {
  //   backgroundColor: 'blue',
  //   borderRadius: 10,
  //   padding: 10,
  //   elevation: 2,
  //   marginBottom: 15,
  // },
  // modalCancelTextStyle: {
  //   color: 'black',
  //   textAlign: 'center',
  // },
  // modalConfirmTextStyle: {
  //   color: 'white',
  //   textAlign: 'center',
  // },
  numbers: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-evenly',
  },
  active: {
    borderWidth: 4,
    borderColor: borderColor,
  },
});

export default NumberBarContainer;
