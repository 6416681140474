import React from 'react';
import { Modal, View, TouchableHighlight, StyleSheet } from 'react-native';

import {default as Text } from '../TextComponent';

const ChoiceModal = ({ visible, choices, heading, disabled, position }) => {
  const modalPosition = position === 2 ? styles.player2 : null

  return (
    <Modal
      visible={visible}
      animated='fade'
      transparent={true}
    >
      <View style={[styles.centeredView, modalPosition]}>
        <View style={styles.modalView}>
          <Text style={styles.modalText}>{heading}</Text>
          <Text style={styles.modalText}>Player Rolled 11!</Text>
          {choices.map((choice, index) => {
            const isDisabled = index === 0 && disabled
            return (
              <TouchableHighlight
                style={isDisabled ? styles.modalConfirmButtonDisabled : styles.modalConfirmButton }
                key={index}
                disabled={isDisabled}
                onPress={() => choice.fn()}>
                <Text style={styles.modalConfirmTextStyle}>{choice.text}</Text>
              </TouchableHighlight>
            )
          })}
        </View>
      </View>
    </Modal>
  )
}

const white = '#fff'
const black = '#000'
const purple = '#603FA3'
const disabledPurp = '#9c94aa'
const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    alignItems: 'center',
    marginTop: 50,
  },
  player2: {
    justifyContent: 'flex-end',
    marginBottom: 50
  },
  modalView: {
    margin: 20,
    backgroundColor: white,
    borderRadius: 20,
    padding: 50,
    width: 350,
    height: 300,
    alignItems: 'center',
    shadowColor: black,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
  },
  modalText: {
    textAlign: 'center',
    fontSize: 10,
    lineHeight: 14,
    paddingTop: 10,
  },
  // modalCancelButton: {
  //   padding: 10,
  //   elevation: 2,
  // },
  modalConfirmButton: {
    backgroundColor: purple,
    borderRadius: 10,
    width: 250,
    height: 45,
    elevation: 2,
    marginBottom: 20,
    marginTop: 20,
    justifyContent: 'center',
    textAlign: 'center'
  },
  modalConfirmButtonDisabled: {
    backgroundColor: disabledPurp,
    borderRadius: 10,
    width: 250,
    height: 45,
    elevation: 2,
    marginBottom: 20,
    justifyContent: 'center',
    textAlign: 'center'
  },
  modalConfirmTextStyle: {
    color: white,
    textAlign: 'center',
    fontSize: 12,
    lineHeight: 14,
    margin: 10,
    paddingTop:10,
  },
});

export default ChoiceModal
