import React, { useState, useEffect } from 'react';
import { ImageBackground, View, StyleSheet, Pressable } from 'react-native';
import { AntDesign } from '@expo/vector-icons';
import { default as Text } from '../shared/TextComponent'
import Profile from './tabs/Profile';
import Settings from './tabs/Settings';
import About from './tabs/About';
import { audio } from '../../functions/soundGarden'
import { http } from '../shared/utils/http';

const SettingsPage = ({ route, navigation }) => {
  const { id } = route.params
  const [user, setUser] = useState({})
  const [loading, setLoading] = useState(false)
  const [activeTab, setActiveTab] = useState(1)

  const getUser = async () => {
    try {
      setLoading(true)
      const userData = await http.get(`/player/${id}`)
      const { data } = userData
      const parsedUser = {
        id:   data.id,
        username: data.username,
        email: data.email,
        losses: Object.keys(data.losses).length,
        wins: Object.keys(data.wins).length,
        matches: Object.keys(data.matches).length,
      }
      setLoading(false)
      setUser(parsedUser)
    } catch (error) {
      setLoading(false)
      console.log('error getting user', error)
    }
  }

  const activeTabHandler = (tab) => {
    setActiveTab(tab)
  }

  const backToLanding = () => {
    audio.okClick()
    navigation.popToTop()
  }

  useEffect(() => {
    getUser()
  }, [])

  return (
    <ImageBackground style={styles.image}
      source={require('../../../assets/background.png')}>
      <View style={styles.container}>
        <View style={styles.header}>
          <Pressable onPress={backToLanding}>
            <AntDesign name="back" size={24} color="black" />
            <Text style={[styles.standardText, styles.backText]}>Back</Text>
          </Pressable>
        </View>
        <View style={styles.btnGroup}>
          <Pressable onPress={() => activeTabHandler(1)} style={activeTab === 1 ? [styles.btn, styles.btnActive] : styles.btn}>
            <Text style={activeTab === 1 ? [styles.btnText, styles.btnActiveText] : styles.btnText}>Profile</Text>
          </Pressable>
          <Pressable onPress={() => activeTabHandler(2)} style={activeTab === 2 ? [styles.btn, styles.btnActive] : styles.btn}>
            <Text style={activeTab === 2 ? [styles.btnText, styles.btnActiveText] : styles.btnText}>Settings</Text>
          </Pressable>
          <Pressable onPress={() => activeTabHandler(3)} style={activeTab === 3 ? [styles.btn, styles.btnActive]: styles.btn}>
            <Text style={activeTab === 3 ? [styles.btnText, styles.btnActiveText] : styles.btnText}>About</Text>
          </Pressable>
        </View>
        {activeTab === 1 &&
          <Profile
            user={user}
            loading={loading}
            refresh={getUser}
          />
        }
        {activeTab === 2 &&
          <Settings />
        }
        {activeTab === 3 &&
          <About />
        }
      </View>
    </ImageBackground>
  );
}


export default SettingsPage

const backgroundColor = '#603FA3'
// const textColor = 'blue'
const btnTextColor = 'white'
const activeTabColor = '#FFCB05'
const activeTabTextColor = '#000000'
const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
    padding: 50,
  },
  image: {
    flex: 1,
    resizeMode: 'cover',
    justifyContent: 'center'
  },
  standardText: {
    fontSize: 10,
    lineHeight: 16,
  },
  btnGroup: {
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    paddingTop: 20,
    paddingBottom: 30,
  },
  btn: {
    backgroundColor: backgroundColor,
    borderRadius: 10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 100,
    height: 50,
    elevation: 2,
    marginBottom: 10,
  },
  btnText: {
    color: btnTextColor,
    textAlign: 'center',
    fontSize: 10,
    lineHeight: 20
  },
  backText: {
    fontSize: 10,
    lineHeight: 24,
  },
  btnActive: {
    backgroundColor: activeTabColor,
  },
  btnActiveText: {
    color: activeTabTextColor,
  },

})
