import React, { useState } from 'react';
import { Animated, Easing, View, StyleSheet } from 'react-native';
import { default as Text } from '../../shared/TextComponent';
import TextInputComponent from '../../shared/TextInputComponent';
import { default as Modal } from '../../shared/modal/ModalContainer';
import { AntDesign } from '@expo/vector-icons';
import { http } from '../../shared/utils/http';

const Profile = ({ user, loading, refresh }) => {
  let pageLoading = loading
  const [username, setUserName] = useState('')
  // const [email, setEmail] = useState('')
  const [usernameConfirmModalVisible, setUsernameConfirmModalVisible] = useState(false)
  const [saveError, setSaveError] = useState(false)

  const LoadingSpinner = () => {
    let spinValue = new Animated.Value(0);

    Animated.timing(
        spinValue,
      {
        toValue: 12,
        duration: 10000,
        easing: Easing.linear,
        useNativeDriver: true
      }
    ).start()

    const spin = spinValue.interpolate({
      inputRange: [0, 1],
      outputRange: ['0deg', '360deg']
    })

    return (
      // eslint-disable-next-line react-native/no-inline-styles
      <Animated.View style={{transform: [{rotate: spin}], alignSelf: 'center' }}>
        <AntDesign name="loading2" size={24} color="black" />
      </Animated.View>
    )
  }

  // const AddOrEditEmail = () => {
  //   const withEdit = (
  //     <View>
  //       {editEmailVisible && <Text style={[styles.topPad, styles.standardText]}>Add an Email and Password to recover your account</Text>}
  //       <View style={[styles.topPad, styles.combinedLabel]}>
  //         { !editEmailVisible &&
  //           <Text style={[styles.topPad, styles.standardText]}>Email: </Text>
  //         }
  //         <Pressable onPress={handleEditEmailClick}>
  //           <Text style={[styles.topPad, styles.standardText, styles.editText, styles.smallPad]}>
  //             {editEmailVisible ? 'Add Email and Password' : <AntDesign name='closecircleo' size={20} color='black'/>}
  //           </Text>
  //         </Pressable>
  //       </View>
  //       { addEmailField &&
  //         <View style={styles.combinedLabel}>
  //           <TextInput
  //             key='email'
  //             onChangeText={(value) =>setEmail(value)}
  //             placeholder='you@example.com'
  //             style={[styles.standardText, styles.inputBox]}/>
  //             <View style={styles.confirmCheck}>
  //               <AntDesign name="checkcircle" size={24} color="green" />
  //             </View>
  //         </View>
  //       }
  //     </View>
  //   )
  //   const withoutEdit = (
  //     <View>
  //       <Text style={[styles.topPad, styles.standardText]}>Email: </Text>
  //       <TextInput
  //         key='email'
  //         value={user.email}
  //         style={[styles.standardText, styles.inputBox, styles.inputBoxDisabled]}
  //         editable={false}
  //       />
  //     </View>
  //   )
  //   return user?.email ? withoutEdit : withEdit
  // }

  const toggleConfirmUsernameModal = () => {
    setUsernameConfirmModalVisible(!usernameConfirmModalVisible)
  }

  const handleConfirmUsername = async () => {
    console.log('confirm username')
    setUsernameConfirmModalVisible(false)
    //send username to server
    setSaveError(false)
    pageLoading = true
    const result = await http.post(`/player/${user.id}/update`, {username})
    if (result.status === 200) {
      console.log(result.data)
      await refresh()
    } else {
      pageLoading = false
      setSaveError(true)
    }
  }

  const unConfirmIsEnabled = username.length > 2 && !user.username

  if (pageLoading) return <LoadingSpinner />

  return (
    <View>
      <Text style={[styles.largePad, styles.howTo]}>Player Profile</Text>
      <Text style={[styles.standardText, styles.topPad]}>
        Username:
      </Text>
      <TextInputComponent
        placeholder={'You'}
        value={user.username || username}
        onChange={setUserName}
        isDisabled={!!user.username}
        hasConfirm={unConfirmIsEnabled}
        onConfirm={toggleConfirmUsernameModal}
      />
      {!user.username &&
        <Text style={[styles.smallText, styles.topPad]}>
          Between 3 and 16 characters. No special characters.
        </Text>
      }
      {saveError &&
        <Text style={[styles.smallText, styles.topPad, styles.errorText]}>
          There was an error saving your username.
        </Text>
      }
        <Text style={[styles.standardText, styles.topPadMedium]}>
          RollDown ID:
        </Text>
        <Text style={[styles.topPad, styles.standardText]}>{user.id}</Text>
      {/* <Text style={[styles.standardText, styles.topPad]}>
        Email: {user.email}
      </Text>
      <TextInput style={[styles.standardText, styles.inputBox]}/> */}
      <Text style={[styles.largePad, styles.howTo]}>Stats</Text>
      <Text style={[styles.standardText, styles.smallPad]}>
        Matches: {user.matches}
      </Text>
      <Text style={[styles.standardText, styles.smallPad]}>
        Wins: {user.wins}
      </Text>
      <Text style={[styles.standardText, styles.smallPad]}>
        Losses: {user.losses}
      </Text>

      <Modal
        visible={usernameConfirmModalVisible}
        animationType='fade'
        heading='Confirm Username'
        confirmText='Confirm'
        cancelText='Cancel'
        bodyText='You are about to add a username. Once saved, it cannot be changed. Are you sure?'
        confirmFunction={handleConfirmUsername}
        cancelFunction={toggleConfirmUsernameModal}
      />

      {/* <Pressable
          style={styles.gotIt}
          android_disableSound={true}
          onPress={backToLanding}
        >
          <Text
            style={styles.confirmTextStyle}>
            Got It!
        </Text>
      </Pressable> */}
  </View>

  )
}

export default Profile;

// const white = '#fff';
// const black = '#000';
// const blue = '#0099ff';
const red = '#ff0000';
// const disabled = '#c1c7d2';


const styles = StyleSheet.create({
  smallPad: {
    paddingTop: 10,
    paddingBottom: 10
  },
  largePad: {
    paddingTop: 40,
    paddingBottom: 20,
  },
  topPad: {
    paddingTop: 10,
  },
  topPadMedium: {
    paddingTop: 20,
  },
  standardText: {
    fontSize: 10,
    lineHeight: 16,
  },
  smallText: {
    fontSize: 6,
    lineHeight: 10,
  },
  errorText: {
    color: red,
  },
})
