import React, { useEffect } from 'react'
import { ImageBackground, View, StyleSheet, Pressable, Alert, BackHandler } from 'react-native'
import { StackActions } from '@react-navigation/native';

import { default as Text } from '../shared/TextComponent';
import { ably } from '../../functions/ablyFactory';
import { MATCHMAKING_CHANNEL } from '@env'

const MatchMakingPage = ({ route, navigation }) => {
  const { user } = route.params

  const matchChannel = ably.channels.get(MATCHMAKING_CHANNEL)
  const playerChannel = ably.channels.get(`player-ch:${user.uid}`)

  const navigateToPlayArea = async (match) => {
    playerChannel.presence.leaveClient(user.uid)
    playerChannel.unsubscribe()
    matchChannel.presence.leaveClient(user.uid)
    matchChannel.detach()
    navigation.dispatch(StackActions.replace(
      'PlayArea', {
        matchId: match,
        user,
       }
    ))
  }

  const quitMatchmaking = async (buttonPress = false) => {
    try {
      playerChannel.presence.leaveClient(user.uid)
      playerChannel.unsubscribe()
      matchChannel.presence.leaveClient(user.uid)
      matchChannel.detach()
      if (buttonPress) {
        navigation.dispatch(StackActions.replace('Landing'))
      }
    } catch (error) {
      console.log('cancelMatchmaking error', error)
    }
  }

  // android only
  useEffect(() => {
    const backAction = () => {
      Alert.alert('Hold on!', 'Are you sure you want to exit?', [
        {
          text: 'Cancel',
          onPress: () => null,
          style: 'cancel',
        },
        { text: 'YES', onPress: () => quitMatchmaking(true) },
      ]);
      return true;
    };

    const backHandler = BackHandler.addEventListener('hardwareBackPress', backAction);

    return () => backHandler.remove();
  }, []);

  useEffect(() => {
    navigation.addListener('beforeRemove', (e) => {
      if (e.data.action.type === 'POP') {
        quitMatchmaking()
      }
    })
  }, [navigation])

  useEffect(() => {
    if (user) {
      matchChannel.attach(function(err) {
        if (err) return console.error('Error attaching to the channel');
        console.log('Successfully Attached to matchMaking')

        matchChannel.presence.enterClient(user.uid, `${user.uid} entered matchMaking`, function (err) {
          if (err) return console.error('Error Entering Presence')
          console.log(`We are successfully present in ${MATCHMAKING_CHANNEL}`)
        })
      })
      playerChannel.subscribe('match-created', (message) => {
        console.log('match-created')
        navigateToPlayArea(message.data.matchId)
      })
    }
  }, [user])

  return (
    <ImageBackground source={require('../../../assets/background.png')} style={styles.image}>
      <View style={styles.container}>
        <Text style={styles.titleText}>Finding Next Game ... </Text>
        <Pressable
          style={styles.confirmButton}
          android_disableSound={true}
          onPress={() => quitMatchmaking(true)}>
          <Text style={styles.confirmTextStyle}>Cancel</Text>
        </Pressable>
      </View>
    </ImageBackground>
  )
}

const white = '#FFFFFF'
const purple = '#603FA3'
const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 20,
  },
  image: {
    flex: 1,
    resizeMode: 'cover',
    justifyContent: 'center'
  },
  titleText: {
    fontSize: 16,
    lineHeight: 18,
    paddingTop: 20
  },
    confirmButton: {
    backgroundColor: purple,
    borderRadius: 10,
    width: 250,
    height: 50,
    elevation: 2,
    marginBottom: 10,
    justifyContent: 'center',
    textAlign: 'center'
  },
  confirmTextStyle: {
    color: white,
    textAlign: 'center',
    fontSize: 10,
    lineHeight: 20
  },
})

export default MatchMakingPage
