import React from 'react';
import { Text, StyleSheet, Pressable } from 'react-native';
import { audio } from '../../functions/soundGarden'

const Number = ({ disabled, number, available, player, selected, chosen, onChosen }) => {
  const isNumberChosen = number === chosen

  const handleChosen = () => {
    audio.keyClick()
    onChosen(number)
  }

  return (
    <Pressable 
      disabled={selected || disabled || !available}
      style={[
          styles.number,
          selected ? styles.selected : null,
          isNumberChosen ? styles.chosen : null,
        ]}
      android_disableSound={true}
      onPress={handleChosen}>
      <Text style={player === 2 ? styles.topText : styles.bottomText}>
        {number}
      </Text>
    </Pressable>
  )
};

const backgroundColorGrey = '#D8D8D8'
const backgroundColorWhite = '#FFFFFF'
const backgroundColorBrown = '#a88f78'
const borderColor = '#000000'

const styles = StyleSheet.create({
  number: {
    borderWidth: 2,
    width: 35,
    alignItems: 'center',
    borderColor: borderColor,
    backgroundColor: backgroundColorBrown,
  },
  topText: {
    position: 'absolute',
    bottom: 0,
    fontWeight: 'bold',
    fontSize: 24,
  },
  bottomText: {
    fontWeight: 'bold',
    fontSize: 24,
  },
  // button: {
  //   fontSize: 8,
  // },
  selected: {
    backgroundColor: backgroundColorGrey,
  },
  chosen: {
    backgroundColor: backgroundColorWhite,
  },
});

export default Number;
